import React, { useState, useCallback } from 'react';
import Amvoxlogopng from '../../assets/Amvoxlogopng.png';
import { useNavigate } from 'react-router';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import { RiFileExcel2Fill } from 'react-icons/ri';
import LayoutNovo from '../../components/LayoutNovo';
import {
  Box,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  IconButton,
  Snackbar,
  Alert,
} from '@mui/material';
import equipe from './DadosMock';
import apontamentos from '../../assets/RelatorioApontamentos.png';
import { postRelatorioApontamentos } from '../../services/relatorioApontamentos.service';
import { useToast } from '../../hooks/components/toast.hook';

const RelatorioApontamentos = () => {
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { addToast } = useToast();
  const handleLogoClick = () => navigate('/inteligencia');
  const [filtro, setFiltro] = useState({
    dataInicial: 'dd/mm/aaaa',
    dataFinal: 'dd/mm/aaaa',
    equipe: '',
  });

  const handleLimparFiltro = useCallback(() => {
    setFiltro({
      dataInicial: '',
      dataFinal: '',
      equipe: '',
    });
  }, []);

  const handleGetRelatorio = useCallback(async () => {
    if (!filtro.dataInicial || !filtro.dataFinal || !filtro.equipe) {
      alert('Por favor, preencha todos os campos!');
      return;
    }

    try {
      const res = await postRelatorioApontamentos(filtro);
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `Relatorio-Apontamentos-${filtro.equipe}.xlsx`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setShowSuccessMessage(true);
    } catch (error) {
      addToast({
        type: 'danger',
        description: 'Erro ao gerar relatorio',
      });
    }
  }, [filtro]);

  return (
    <LayoutNovo>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          fontFamily: 'Poppins, sans-serif',
          padding: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: '24px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: 2,
            }}
          >
            <Box>
              <IconButton onClick={handleLogoClick} sx={{ cursor: 'pointer' }}>
                <ChevronLeftIcon />
              </IconButton>
              <img
                src={Amvoxlogopng}
                style={{ width: '150px', height: '100px', cursor: 'pointer' }}
              />
            </Box>
            <Typography
              sx={{ fontWeight: '500', color: '#333333', fontSize: '25px' }}
            >
              Relatórios
            </Typography>
          </Box>
          {showSuccessMessage && (
            <Snackbar
              open={showSuccessMessage}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              onClose={() => setShowSuccessMessage(false)}
              autoHideDuration={5000}
            >
              <Alert
                onClose={() => setShowSuccessMessage(false)}
                variant="outlined"
                severity="success"
                sx={{
                  borderRadius: '8px',
                  fontWeight: 'bold',
                  fontSize: '14px',
                  fontFamily: 'Poppins',
                }}
              >
                Relatório exportado.
              </Alert>
            </Snackbar>
          )}
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            width: '100%',
            marginBottom: '24px',
          }}
        >
          <Typography
            sx={{
              fontWeight: 'bolder',
              color: '#333333',
              fontSize: '25px',
              fontFamily: 'Poppins, sans-serif',
            }}
          >
            Relatório de Apontamento:
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: 2,
            width: '100%',
          }}
        >
          <TextField
            label="Data Inicial"
            type="date"
            size="small"
            sx={{
              backgroundColor: '#fff',
              display: 'flex',
              flex: '1 2 ',
              width: '225px',
              '& .MuiInputBase-root': {
                height: '48px',
                borderRadius: '8px',
                '&:focus-within': {
                  border: '1px solid lightgray',
                },
              },
            }}
            value={filtro.dataInicial}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                dataInicial: e.target.value,
              })
            }
            inputProps={{
              style: { height: '48px', width: '100%' },
            }}
          />
          <TextField
            fullWidth
            label="Data Final"
            type="date"
            size="small"
            sx={{
              backgroundColor: '#fff',
              display: 'flex',
              flex: '1 2',
              '& .MuiInputBase-root': {
                height: '48px',
                borderRadius: '8px',
                '&:focus-within': {
                  border: '1px solid lightgray',
                },
              },
            }}
            value={filtro.dataFinal}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                dataFinal: e.target.value,
              })
            }
            inputProps={{
              style: { height: '48px', width: '100%' },
            }}
          />
          <FormControl sx={{ flex: '3 1 ' }}>
            <InputLabel id="select-equipe-label" shrink>
              Equipe
            </InputLabel>
            <Select
              size="small"
              label="Equipe"
              labelId="select-equipe-label"
              value={filtro.equipe}
              onChange={(e) =>
                setFiltro({
                  ...filtro,
                  equipe: e.target.value,
                })
              }
              displayEmpty
              sx={{
                flex: '3 1 ',
                height: '48px',
                borderRadius: '8px',
                backgroundColor: '#fff',
                '& .MuiInputBase-root': {
                  height: '48px',
                  borderRadius: '8px',
                  '&:focus-within': {
                    border: '1px solid lightgray',
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                Selecione
              </MenuItem>
              {equipe.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            startIcon={<CleaningServicesIcon />}
            onClick={handleLimparFiltro}
            variant="outlined"
            color="inherit"
            size="large"
            sx={{ flex: '1 3', fontWeight: 'bold' }}
          >
            Limpar filtros
          </Button>

          <Button
            type="button"
            variant="contained"
            color="success"
            size="large"
            onClick={handleGetRelatorio}
            sx={{
              textTransform: 'capitalize',
              color: '#fff',
              flex: '1 3',
              fontWeight: 'bold',
              justifyContent: 'center',
              width: 'auto',
            }}
            startIcon={<RiFileExcel2Fill size={20} color="white" />}
          >
            {'EXPORTAR PARA EXCEL'}{' '}
          </Button>
        </Box>
        <Box sx={{ width: '100%', marginTop: '30px' }}>
          <img
            style={{
              width: '100%',
              height: '580px',
              objectFit: 'fill',
              border: '1px solid lightgray',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
              borderRadius: '8px',
            }}
            src={apontamentos}
            alt="img"
            loading="lazy"
            decoding="async"
          />
        </Box>
      </Box>
    </LayoutNovo>
  );
};

export default RelatorioApontamentos;
