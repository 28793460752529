import React, { useCallback, useContext, useEffect, useState } from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
} from '@mui/material';
import { BsFillMegaphoneFill } from 'react-icons/bs';
import XTabelaChamados from './Xtabela/TabelaChamados';
import ModalChamadoDetalhes from './Xmodal';
import { useToast } from '../../hooks/components/toast.hook';
import {
  DeleteChamadosDetalheId,
  GetChamadosDetalheId,
  PutChamadosDetalhe,
} from '../../services/chamadosDetalhe/chamadosDetalheX.service';
import { formatDateTime } from '../../utils/formatDateInput';
import { formatDateSendApi } from '../../utils/formatDateInput';
import { SiMicrosoftexcel } from 'react-icons/si';
import { apiFabrica, apiInteligencia } from '../../services/apis';
import ModalDetalhes from './XmodalDetalhes';
import { RestricaoContext } from '../../hooks/acesso-ti/acesso-restrito-ti.hook';
import { useLocation } from 'react-router';
import { EnviarFormEmail } from '../../services/email.service';
import { buscarMembroEquipe } from '../../services/equipe.service';
import useUsuarioLocal from '../../hooks/usuarioLocal.hook';
import { GetChamadosXFiltrado } from '../../services/chamados/chamadosX.service';
import { useNavigate } from 'react-router-dom';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { postChamadosDetalhesX } from '../../services/chamados/chamadosX.service';
import NumberBox3 from '../../components/NumberBox3';
import debounce from '../../utils/debounce';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { BuscarFactory } from '../../services/core/apiFabricaFactory.service';
import { consultaSetores } from '../../services/setores/setores.service';

const ExcelJS = require('exceljs');

const interfaceDadostabela = {
  dataAbertura: '',
  dataAtualizacao: '',
  descricao: '',
  dias: 0,
  id: 0,
  quantidadeOcorrecias: 0,
  responsavelDemandado: '',
  responsavelDemandante: '',
  setorDemandado: '',
  setorDemandante: '',
  situacao: '',
  status: '',
  tipo: '',
  categoria: '',
  titulo: '',
  urgencia: '',
  idStatus: null,
  idCategoria: null,
  idSituacao: null,
  emailResponsavelDemandado: '',
  emailResponsavelDemandante: '',
};

const interfaceDadosCard = {
  chamadosX: interfaceDadostabela,
  qtdAberto: '',
  qtdFechado: '',
  qtdTotal: '',
};

const statusSelect = [
  { label: 'TODOS', value: '0' },
  { label: 'FECHADO', value: '1' },
  { label: 'ABERTO', value: '2' },
];

export default function XChamados() {
  const { setRouteName, error } = useContext(RestricaoContext);
  const location = useLocation().pathname;
  const [removeLoading, setRemoveLoading] = useState(false);
  const [idChamadoSelecionado, setIdChamadoSelecionado] = useState(null);
  const [emailRespDemandante, setEmailRespDemandante] = useState(null);
  const [emailRespDemandado, setEmailRespDemandado] = useState(null);
  const [emailSolicitante, setEmailSolicitante] = useState(null);
  const [listaChamadosDetalhes, setListaChamadosDetalhes] = useState();
  const [formData, setFormData] = useState();
  const [listaMembros, setListaMembros] = useState(null);
  const [listaChamados, setListaChamados] = useState();
  const [detalheSelecionado, setDetalheSelecionado] = useState(null);
  const [detalheId, setDetalheid] = useState(null);
  const [idChamadoSel, setIdChamadoSel] = useState(null);
  const { addToast } = useToast();
  const [dadosTabela, setDadosTabela] = useState([interfaceDadostabela]);
  const [dadosCard, setDadosCard] = useState(interfaceDadosCard);

  const [setoresInfo, setSetoresInfo] = useState([]);

  const { email } = useUsuarioLocal();
  const [filtro, setFiltro] = useState({
    id: null,
    status: 2,
    EmailUsuario: email,
  });

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(!open);

  const [showModalDet, setShowModalDet] = useState(false);

  const handleOpenDet = (id) => {
    setShowModalDet(true),
      setIdChamadoSelecionado(id),
      setEmailRespDemandante(dadosTabela.emailResponsavelDemandante),
      setEmailRespDemandado(dadosTabela.emailResponsavelDemandado),
      setEmailSolicitante(null);
  };
  const handleCloseDet = () => {
    setShowModalDet(false),
      setIdChamadoSelecionado(null),
      setDetalheSelecionado(null);
  };

  const handleFetch = () => {
    setRouteName(location);
    GetChamadosXFiltrado(filtro)
      .then((res) => {
        setListaChamados(res.value.chamadosX);
        setDadosCard(res.value);
      })
      .catch(() =>
        addToast({
          type: 'danger',
          title: 'Erro ao listar Chamados !',
        })
      )
      .finally(() => setRemoveLoading(true));

    buscarMembroEquipe()
      .then((res) => {
        setListaMembros(res);
      })
      .catch(() => {
        addToast({
          type: 'danger',
          title: 'Erro ao listar Membros!',
        });
      });
  };

  const handleSetores = () => {
    consultaSetores().then((res) => {
      setSetoresInfo(res);
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    debounce(() => {
      setFiltro((prevFiltro) => ({ ...prevFiltro, status: value }));
    });
  };

  const handleClear = () => {
    setFiltro({
      id: 0,
      status: 0,
      EmailUsuario: email,
    });
  };

  const handleEnviarEmailAtribuido = useCallback((value) => {
    const data = new Date().toLocaleTimeString('pt-BR');
    const assunto = 'Chamado Atribuido' + data;

    const { id, responsavel, tipo, categoria, urgencia, titulo, descricao } =
      value;
    const destinatario = responsavel;

    const body = `<table
    align="center"
    role="presentation"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    style="max-width: 37.5em"
  >
    <tr style="width: 100%">
      <td>
        <table
          style="
            background-color: rgb(190, 190, 190);
            display: flex;
            justify-content: center;
            aling-items: center;
            padding: 30px;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <td style="width="25%">


              </td>
            </tr>
          </tbody>
        </table>
        <table
          style="
            border: 1px solid rgb(0, 0, 0, 0.1);
            border-radius: 3px;
            overflow: hidden;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <table
                width="100%"
                style="padding: 20px 40px; padding-bottom: 0"
                align="center"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>
                      <p
                        style="
                          font-size: 14px;
                          line-height: 24px;
                          margin: 16px 0;
                          color: rgb(0, 0, 0, 0.5);
                          margin-top: -5px;
                        "
                      >
                        *${data}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Número do Chamado: </b>  ${id}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Titulo: </b>  ${titulo}
                      </p>
                     <b>Tipo: </b>  ${tipo}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Categoria: </b>  ${categoria}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Urgencia: </b>  ${urgencia}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Descrição: </b>  ${descricao}
                      </p>

                      <hr/>
                    </td>
                  </tr>
                </tbody>

                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>
                      <p
                        style="font-size:12px;line-height:24px; 0;text-align:center;color:rgba(71, 71, 71, 0.7)"
                      >
                        © 2024 | REISTAR INDÚSTRIA E COMÉRCIO DE ELETRÔNICOS
                        LTDA
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>`;

    const newValue = { destinatario, body, assunto };

    EnviarFormEmail(newValue)
      .then(() => {
        addToast({
          type: 'success',
          description: 'Sucesso ao enviar email',
        });
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          description: 'Falha ao enviar email',
        });
      });
  });

  const handleEnviarEmail = useCallback((value) => {
    const data = new Date();
    const assunto = 'Nova Ocorrência ' + data;
    const {
      descricao,
      idChamado,
      observacao,
      previsaoEntrega,
      previsaoInicio,
      responsavelAprovacao,
      responsavelExecucao,
      emailRespDemandado,
      emailRespDemandante,
    } = value;
    const destinatario = responsavelAprovacao || emailRespDemandante;

    // const body = `Descrição: ${descricaoBody} \n Tempo: ${tempoBody} \n Status: ${statusBody}`
    const body = `<table
    align="center"
    role="presentation"
    cellspacing="0"
    cellpadding="0"
    border="0"
    width="100%"
    style="max-width: 37.5em"
  >
    <tr style="width: 100%">
      <td>
        <table
          style="
            background-color: rgb(190, 190, 190);
            display: flex;
            justify-content: center;
            aling-items: center;
            padding: 30px;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <td style="width="25%">
              <svg width="201" height="54" viewBox="0 0 201 54" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9824 20.1018C6.72736 30.8119 1.44431 39.866 1.22831 40.217L0.841309 40.874H7.25837H13.6754L15.1064 37.814L16.5464 34.754H23.3145H30.0826L30.1276 35.222C30.1906 35.78 30.5416 40.442 30.5416 40.703C30.5416 40.874 30.6676 40.874 36.6706 40.874H42.8087L42.7457 40.487C42.7097 40.28 41.3057 31.3159 39.6317 20.5788C37.9486 9.83269 36.5536 0.958591 36.5266 0.84159L36.4816 0.643588H30.4156H24.3495L12.9824 20.1018ZM28.4266 19.9578C28.8496 23.0178 29.1916 25.5829 29.1916 25.6369C29.1916 25.7269 28.3366 25.7539 25.2225 25.7539C23.0445 25.7539 21.2715 25.7179 21.2895 25.6819C21.3975 25.4029 27.5985 14.3237 27.6255 14.3507C27.6525 14.3687 28.0125 16.8978 28.4266 19.9578Z" fill="black"/>
<path d="M181.428 9.19276C177.864 13.4948 174.93 16.9868 174.894 16.9508C174.858 16.9148 174.237 15.7268 173.508 14.2958L172.185 11.7128H166.56C161.952 11.7128 160.944 11.7308 160.971 11.8298C160.989 11.8928 162.591 14.9528 164.535 18.6339C166.911 23.1159 168.045 25.3479 167.991 25.4199C167.919 25.5099 159.207 36.094 156.3 39.6131L155.292 40.8281L161.682 40.9091C166.992 40.9721 168.09 40.9631 168.207 40.8641C168.288 40.7921 169.431 39.4151 170.745 37.7951L173.13 34.843L174.696 37.7861C175.551 39.3971 176.307 40.7831 176.361 40.8551C176.469 40.9721 177.126 40.9811 182.004 40.9361C185.046 40.9001 187.548 40.8641 187.566 40.8461C187.593 40.8101 186.747 39.1991 181.536 29.353C180.762 27.895 180.123 26.6529 180.105 26.6079C180.087 26.5539 182.553 23.5209 185.577 19.8579C188.601 16.1948 192.993 10.8668 195.342 8.02275C197.691 5.17872 199.887 2.51469 200.229 2.10068L200.841 1.36268H194.37H187.899L181.428 9.19276Z" fill="#FF0000"/>
<path d="M144.302 11.0464C141.521 11.2894 139.694 11.7034 137.813 12.5224C134.654 13.8904 131.909 16.3655 130.208 19.3625C128.966 21.5675 128.12 24.1505 127.823 26.6886C127.679 27.9306 127.769 30.5856 127.994 31.6926C128.741 35.3197 130.892 38.3347 133.817 39.8557C135.194 40.5667 136.994 41.0887 138.947 41.3317C140.09 41.4757 143.366 41.4847 144.482 41.3407C149.693 40.6747 153.626 38.4067 156.38 34.4827C157.613 32.7276 158.972 29.4426 159.414 27.1836C160.215 23.0435 159.549 19.3805 157.433 16.2035C155.129 12.7654 151.7 11.1814 146.282 11.0464C145.418 11.0284 144.527 11.0284 144.302 11.0464ZM146.579 18.8045C147.29 19.0655 148.226 19.7495 148.541 20.2445C149.351 21.5225 149.522 23.3855 149.072 26.0676C148.541 29.1996 146.867 32.2146 145.076 33.2676C144.131 33.8166 142.817 34.0597 141.827 33.8526C140.144 33.5106 138.749 31.9896 138.308 30.0186C138.128 29.1726 138.173 26.9946 138.407 25.9326C138.794 24.1595 139.748 21.9455 140.63 20.7665C141.422 19.7135 142.556 18.9575 143.762 18.6875C144.581 18.5075 145.904 18.5615 146.579 18.8045Z" fill="black"/>
<path d="M68.9987 11.1737C66.1097 11.4797 63.3737 12.8297 60.7636 15.2418C60.2506 15.7098 59.8816 15.9888 59.8816 15.9078C59.8816 15.8358 60.0616 14.9088 60.2866 13.8647C60.5116 12.8117 60.6916 11.9207 60.6916 11.8757C60.6916 11.8397 58.4236 11.8037 55.6516 11.8037C52.8796 11.8037 50.6115 11.8217 50.6115 11.8487C50.6115 11.9027 44.6805 40.37 44.6085 40.676L44.5635 40.874L50.0355 40.856L55.4986 40.829L56.3356 36.824C56.8036 34.619 57.5056 31.2619 57.9016 29.3539C58.6216 25.9519 59.0086 24.5029 59.5216 23.3598C60.3046 21.6138 61.7626 20.1288 63.2837 19.5348C64.0127 19.2378 65.2547 19.2378 65.7947 19.5168C66.8927 20.1018 66.9827 21.3348 66.2267 25.1239C65.8667 26.9509 63.1037 40.28 62.9957 40.721C62.9597 40.865 63.2477 40.874 68.4137 40.856L73.8588 40.829L74.9208 35.699C76.6848 27.2029 76.8828 26.3119 77.3328 24.9439C78.0348 22.8198 78.7548 21.5418 79.7988 20.5608C80.6988 19.7238 81.8058 19.2738 82.9668 19.2738C83.7409 19.2738 84.1729 19.4178 84.5509 19.8138C85.0099 20.2908 85.1179 20.7678 85.0639 22.0458C85.0099 23.1888 84.5329 25.6639 82.3998 35.789L81.3378 40.829L86.7379 40.856C89.7079 40.865 92.1649 40.847 92.2009 40.811C92.2819 40.721 96.296 21.5328 96.431 20.5788C96.602 19.3908 96.584 17.3658 96.395 16.5288C95.846 14.1617 94.217 12.2717 92.1109 11.5877C91.0039 11.2277 90.3469 11.1377 88.8169 11.1377C87.0979 11.1467 86.0179 11.3087 84.5329 11.8037C82.4898 12.4787 80.6628 13.6127 78.5118 15.5298L77.9358 16.0428L77.8278 15.7458C76.7208 12.5417 73.2198 10.7327 68.9987 11.1737Z" fill="black"/>
<path d="M98.762 11.9195C98.762 11.9825 99.491 18.4626 100.382 26.3197C101.273 34.1768 102.002 40.6658 102.002 40.7378C102.002 40.8548 102.803 40.8728 108.536 40.8548L115.07 40.8278L122.801 26.3827C127.049 18.4356 130.532 11.9105 130.532 11.8655C130.532 11.8295 127.994 11.8025 124.889 11.8025L119.237 11.8115L115.232 19.9656C113.027 24.4477 111.182 28.1737 111.137 28.2457C111.065 28.3357 110.831 25.9867 110.336 20.2446C109.949 15.7716 109.616 12.0455 109.589 11.9555C109.553 11.8115 109.22 11.8025 104.153 11.8025C99.761 11.8025 98.762 11.8205 98.762 11.9195Z" fill="black"/>
<path d="M190.167 36.1033C188.367 36.8233 187.998 39.2894 189.519 40.4324C190.041 40.8284 190.455 40.9634 191.157 40.9634C193.227 40.9544 194.379 38.4164 193.029 36.8413C192.345 36.0403 191.112 35.7163 190.167 36.1033ZM191.598 36.5623C192.192 36.7153 192.768 37.2643 192.957 37.8493C193.389 39.2174 192.363 40.5134 190.923 40.4054C189.528 40.3064 188.718 38.8664 189.339 37.5973C189.654 36.9493 190.32 36.4993 190.995 36.4723C191.112 36.4633 191.382 36.5083 191.598 36.5623Z" fill="black"/>
<path d="M189.933 38.3897V39.7937H190.203H190.473V39.2537C190.473 38.7227 190.473 38.7137 190.734 38.7137C190.95 38.7137 191.049 38.7947 191.337 39.2537C191.661 39.7577 191.715 39.7937 192.03 39.7937C192.417 39.7937 192.417 39.8027 191.94 39.1547L191.643 38.7317L191.958 38.4167C192.345 38.0297 192.372 37.6697 192.057 37.3007C191.85 37.0577 191.805 37.0487 190.887 37.0217L189.933 36.9857V38.3897ZM191.364 37.4627C191.58 37.5437 191.643 37.6157 191.643 37.8047C191.643 38.1557 191.481 38.2637 190.95 38.2637H190.473V37.8767C190.473 37.6607 190.5 37.4537 190.536 37.4267C190.626 37.3367 191.04 37.3547 191.364 37.4627Z" fill="black"/>
<path d="M111.146 47.3171C110.345 47.5691 109.697 48.1091 109.31 48.8381C109.094 49.2341 109.067 49.3871 109.067 50.1431C109.067 50.8631 109.103 51.0611 109.274 51.3851C109.598 51.9881 110.156 52.5461 110.723 52.8161C111.164 53.0321 111.335 53.0681 112.037 53.0681C112.676 53.0681 112.937 53.0231 113.27 52.8701C113.819 52.6181 114.548 51.9341 114.773 51.4571C115.196 50.5931 115.178 49.5131 114.737 48.7391C114.458 48.2531 113.783 47.6501 113.279 47.4251C112.73 47.1821 111.722 47.128 111.146 47.3171ZM113.27 48.0461C113.801 48.3431 114.269 48.9461 114.431 49.5401C114.746 50.7011 114.026 52.0241 112.865 52.4561C112.361 52.6361 111.551 52.6001 111.047 52.3571C110.228 51.9791 109.562 50.9801 109.562 50.1431C109.562 49.6661 109.832 49.0001 110.201 48.5771C110.786 47.9111 111.29 47.7041 112.19 47.7491C112.649 47.7671 112.901 47.8391 113.27 48.0461Z" fill="black"/>
<path d="M123.612 47.2991C122.694 47.6051 122.28 48.4781 122.667 49.2971C122.874 49.7291 123.153 49.9001 124.278 50.2871C125.439 50.6921 125.754 50.9261 125.826 51.4661C125.871 51.7991 125.844 51.8801 125.61 52.1141C125.277 52.4471 124.692 52.6091 124.116 52.5371C123.603 52.4651 123.333 52.3571 122.82 52.0061L122.433 51.7541L122.325 51.9791C122.19 52.2851 122.361 52.4741 123.09 52.8071C123.567 53.0321 123.756 53.0681 124.413 53.0681C125.214 53.0591 125.574 52.9421 126.015 52.5371C126.672 51.9431 126.627 50.9171 125.934 50.3861C125.763 50.2601 125.196 50.0081 124.665 49.8281C123.648 49.4771 123.288 49.2341 123.189 48.8291C123.045 48.2441 123.468 47.8121 124.287 47.7401C124.782 47.6951 125.25 47.8031 125.952 48.1091C126.051 48.1541 126.123 48.1001 126.195 47.9381C126.258 47.8121 126.303 47.6951 126.303 47.6771C126.303 47.6591 126.096 47.5511 125.835 47.4431C125.241 47.1911 124.17 47.1191 123.612 47.2991Z" fill="black"/>
<path d="M163.365 47.2361C162.303 47.2721 162.303 47.2721 162.303 47.4881C162.303 47.7131 162.303 47.7131 163.248 47.7131H164.193L164.211 50.3861L164.238 53.0681H164.508H164.778L164.805 50.3861L164.823 47.7131H165.768C166.704 47.7131 166.713 47.7131 166.713 47.4971C166.713 47.3081 166.659 47.2811 166.245 47.2271C165.984 47.2001 165.462 47.1821 165.093 47.1911C164.724 47.2001 163.941 47.2271 163.365 47.2361Z" fill="black"/>
<path d="M181.32 47.2991C180.447 47.5871 179.79 48.1361 179.385 48.9101C179.097 49.4771 179.061 50.4851 179.313 51.1511C179.565 51.8261 180.15 52.4651 180.816 52.7981C181.275 53.0321 181.437 53.0591 182.148 53.0681C182.841 53.0681 183.03 53.0321 183.462 52.8341C185.352 51.9521 185.811 49.4951 184.353 48.0821C183.57 47.3261 182.265 46.984 181.32 47.2991ZM183.264 47.9921C184.767 48.7481 185.082 50.6741 183.903 51.8531C183.381 52.3751 182.976 52.5281 182.148 52.5281C181.302 52.5191 180.888 52.3481 180.366 51.7721C179.916 51.2771 179.745 50.8361 179.736 50.1431C179.727 49.4051 179.97 48.8471 180.546 48.3521C181.338 47.6501 182.319 47.5241 183.264 47.9921Z" fill="black"/>
<path d="M100.833 50.1795V53.1136H101.103H101.373V50.6835C101.373 49.3425 101.4 48.2535 101.445 48.2535C101.481 48.2535 102.3 49.3425 103.263 50.6655C104.874 52.8616 105.054 53.0686 105.315 53.0956L105.603 53.1226V50.1975V47.2635H105.333H105.063L105.045 49.6755L105.018 52.0965L103.263 49.7025C101.553 47.3715 101.49 47.3085 101.166 47.2815L100.833 47.2545V50.1795Z" fill="black"/>
<path d="M130.083 50.1878V53.1128H132.063H134.043V52.8428V52.5728H132.333H130.623V51.4478V50.3228H132.108H133.593V50.0978V49.8728H132.108H130.623V48.7927V47.7127H132.288H133.953V47.4877V47.2627H132.018H130.083V50.1878Z" fill="black"/>
<path d="M137.481 49.395C137.508 51.276 137.526 51.573 137.679 51.852C138.084 52.626 138.714 53.022 139.632 53.094C140.757 53.175 141.648 52.734 142.062 51.879C142.26 51.474 142.278 51.33 142.305 49.35L142.341 47.262H142.017H141.693V49.242C141.693 51.699 141.603 52.014 140.748 52.401C140.001 52.743 139.002 52.581 138.516 52.041C138.084 51.564 138.003 51.087 138.003 49.017V47.262H137.724H137.454L137.481 49.395Z" fill="black"/>
<path d="M150.441 50.1604L150.468 53.0674H150.738H151.008L151.035 52.1404L151.062 51.2224H151.845C152.277 51.2224 152.718 51.1954 152.826 51.1684C152.997 51.1234 153.105 51.2404 153.636 52.0504C153.969 52.5544 154.293 53.0044 154.338 53.0404C154.464 53.1304 154.923 53.1304 154.923 53.0404C154.923 52.9954 154.626 52.5454 154.266 52.0324L153.6 51.0964L154.05 50.8174C154.671 50.4484 154.878 50.0344 154.878 49.1524C154.878 48.3244 154.662 47.9194 154.041 47.5503C153.627 47.3163 153.573 47.3073 152.016 47.2803L150.423 47.2443L150.441 50.1604ZM153.636 47.9554C154.167 48.2344 154.338 48.5314 154.338 49.1884C154.329 50.2954 153.726 50.6824 151.98 50.6824H151.053V49.1884V47.6854L152.178 47.7304C153.042 47.7754 153.375 47.8204 153.636 47.9554Z" fill="black"/>
<path d="M158.613 50.1878V53.1128H158.883H159.153V50.1878V47.2627H158.883H158.613V50.1878Z" fill="black"/>
<path d="M169.791 50.1615L169.818 53.0686L170.07 53.0956L170.313 53.1226V50.6295C170.313 49.0815 170.349 48.1635 170.394 48.2085C170.439 48.2625 170.97 49.2255 171.564 50.3685C172.365 51.8985 172.689 52.4386 172.806 52.4386C172.923 52.4386 173.256 51.8805 174.093 50.2785L175.218 48.1185L175.245 50.6205L175.263 53.1226L175.515 53.0956L175.758 53.0686L175.785 50.1615L175.803 47.2635H175.425H175.038L173.922 49.4325L172.815 51.6015L171.681 49.4325L170.547 47.2635H170.16H169.773L169.791 50.1615Z" fill="black"/>
</svg>


              </td>
            </tr>
          </tbody>
        </table>
        <table
          style="
            border: 1px solid rgb(0, 0, 0, 0.1);
            border-radius: 3px;
            overflow: hidden;
          "
          align="center"
          border="0"
          cellpadding="0"
          cellspacing="0"
          role="presentation"
          width="100%"
        >
          <tbody>
            <tr>
              <table
                width="100%"
                style="padding: 20px 40px; padding-bottom: 0"
                align="center"
                role="presentation"
                cellspacing="0"
                cellpadding="0"
                border="0"
              >
                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>
                      <p
                        style="
                          font-size: 14px;
                          line-height: 24px;
                          margin: 16px 0;
                          color: rgb(0, 0, 0, 0.5);
                          margin-top: -5px;
                        "
                      >
                        *${data}
                      </p>
                      <p
                      style="
                        font-size: 16px;
                        line-height: 24px;
                        margin: 16px 0;
                        margin-top: -5px;
                      "
                    >
                      <b>Chamado Nº: </b>  ${idChamado}
                      </p>
                      <p
                      style="
                        font-size: 16px;
                        line-height: 24px;
                        margin: 16px 0;
                        margin-top: -5px;
                      "
                    >
                      <b>Responsável Execução: </b>  ${
                        responsavelExecucao || emailRespDemandado
                      }
                      </p>

                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                      <b>Previsão de Inicio: </b>  ${previsaoInicio}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                      <b>Previsão de Entrega: </b>  ${previsaoEntrega}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Descrição: </b>  ${descricao}
                      </p>
                      <p
                        style="
                          font-size: 16px;
                          line-height: 24px;
                          margin: 16px 0;
                          margin-top: -5px;
                        "
                      >
                     <b>Observação: </b>  ${observacao}
                      </p>


                    </td>
                  </tr>
                </tbody>
                <tbody style="width: 100%">
                  <tr style="width: 100%">
                    <td>
                      <p
                        style="font-size:12px;line-height:24px; 0;text-align:center;color:rgba(71, 71, 71, 0.7)"
                      >
                        © 2023 | REISTAR INDÚSTRIA E COMÉRCIO DE ELETRÔNICOS
                        LTDA
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </table>`;

    const newValue = { destinatario, body, assunto };

    EnviarFormEmail(newValue)
      .then(() => {
        addToast({
          type: 'success',
          description: 'Sucesso ao enviar email',
        });
      })
      .catch((_err) => {
        addToast({
          type: 'danger',
          title: 'Falha ao enviar email',
          description: error.response.data,
        });
      });
  });

  const loaderDataChamadosDetalhe = (id) => {
    apiInteligencia
      .get(`/ChamadoDetalheX/${id}`)
      .then((res) => {
        setListaChamadosDetalhes(res.data.value.chamadosDetalhes);
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Erro ao listar Detalhes do Chamado !' + error,
        });
      });
  };

  const handleSubmitDet = (body) => {
    const formData = new FormData();

    formData.append('idChamado', body.idChamado);
    formData.append('descricao', body.descricao);
    formData.append('previsaoEntrega', body.previsaoEntrega);
    formData.append('previsaoInicio', body.previsaoInicio);
    formData.append('imagemDetalhe', body.imagemDetalhe);
    formData.append(
      'responsavelAprovacao',
      body.responsavelAprovacao || body.emailRespDemandante
    );
    formData.append(
      'responsavelExecucao',
      body.responsavelExecucao || body.emailRespDemandado
    );

    formData.append('observacao', body.observacao);

    const { idChamado } = body;
    postChamadosDetalhesX(formData)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso ao criar Detalhes!',
        });
        setTimeout(() => {
          loaderDataChamadosDetalhe(idChamado);
        }, 1000);
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Erro ao criar Detalhes do Chamado !',
          description: error.response.data.title,
        });
      })
      .finally(() => {
        handleFetch();
      });
  };

  const handleGetChamadoDet = (idChamado, idDetalhe) => {
    GetChamadosDetalheId(idChamado)
      .then((res) => {
        setDetalheid(idDetalhe);
        setIdChamadoSel(idChamado);
        setDetalheSelecionado(res.value);
      })
      .finally(() => handleOpenDet());
  };

  const handleUpdateDet = (body) => {
    const { idChamado } = body;

    PutChamadosDetalhe(body)
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso ao Atualizar Detalhes!',
        });
        setTimeout(() => {
          loaderDataChamadosDetalhe(idChamado);
        }, 1000);
      })
      .finally(() => {
        handleFetch();
      });
  };

  const handleDeleteChamadoDet = (idDetalhe) => {
    DeleteChamadosDetalheId(idDetalhe).then(() => {
      addToast({
        type: 'success',
        title: 'Sucesso ao Deletar Detalhes!',
      });
    });
  };

  useEffect(() => {
    handleSetores();
    handleFetch();
    const intervalo = setInterval(handleFetch, 30000);
    handleFetch();
    return () => {
      clearInterval(intervalo);
    };
  }, [filtro]);

  const exportExcelFile = () => {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('My Sheet');
    sheet.properties.defaultRowHeight = 20;

    sheet.columns = [
      {
        header: 'Nº Chamado',
        key: 'id',
        width: 8,
      },
      {
        header: 'Título',
        key: 'titulo',
        width: 30,
      },
      {
        header: 'Status',
        key: 'status',
        width: 10,
      },
      {
        header: 'Dias',
        key: 'dias',
        width: 10,
      },
      {
        header: 'Categoria',
        key: 'categoria',
        width: 30,
      },
      {
        header: 'Última Atualização',
        key: 'atualizacao',
        width: 20,
      },
      {
        header: 'Data Abertura',
        key: 'abertura',
        width: 20,
      },
      {
        header: 'Nível de Urgência',
        key: 'urgencia',
        width: 10,
      },
      {
        header: 'Solicitante',
        key: 'solicitante',
        width: 20,
      },
      {
        header: 'Setor Solicitante',
        key: 'setorSolicitante',
        width: 15,
      },
      {
        header: 'Responsável',
        key: 'responsavel',
        width: 20,
      },

      {
        header: 'Setor Demandado',
        key: 'setorDemandado',
        width: 15,
      },
      {
        header: 'Situação',
        key: 'situacao',
        width: 20,
      },
      {
        header: 'Descrição',
        key: 'descricao',
        width: 50,
      },
    ];

    listaChamados.map((item) => {
      sheet.addRow({
        id: item.id,
        titulo: item.titulo,
        status: item.status,
        dias: item.dias,
        categoria: item.categoria,
        atualizacao: formatDateTime(item.dataAtualizacao),
        abertura: formatDateTime(item.dataAbertura),
        urgencia: item.urgencia,
        solicitante: item.responsavelDemandante,
        setorSolicitante: item.setorDemandante,
        responsavel: item.responsavelDemandado,
        setorDemandado: item.setorDemandado,
        situacao: item.situacao,
        descricao: item.descricao,
        emailRespDemandante: item.emailRespDemandante,
        emailRespDemandado: item.emailRespDemandado,
      });

      return null;
    });

    workbook.xlsx.writeBuffer().then(function (data) {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const url = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = `Chamados - ${formatDateSendApi(new Date())}.xlsx`;
      anchor.click();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <LayoutNovo setorColor={'inteligencia'}>
      {/* <ModalAcessoRestrito open={showModalAccess} LiberarAcesso={LiberarAcesso} error={error} /> */}

      <ModalChamadoDetalhes
        open={open}
        dadosTabela={dadosTabela}
        onClose={handleClose}
        handleEnviarEmail={handleEnviarEmailAtribuido}
        listaMembros={listaMembros}
        openModalDet={handleOpenDet}
        handleFetch={handleFetch}
        handleDeleteChamadoDet={handleDeleteChamadoDet}
        handleGetChamadoDet={handleGetChamadoDet}
        listaChamadosDetalhes={listaChamadosDetalhes}
        imagem={formData}
        setListaChamadosDetalhes={setListaChamadosDetalhes}
        emailRespDemandante={emailRespDemandante}
        emailRespDemandado={emailRespDemandado}
        setoresInfo={setoresInfo}
      />

      <ModalDetalhes
        open={showModalDet}
        onClose={handleCloseDet}
        listaMembros={listaMembros}
        handleEnviarEmail={handleEnviarEmail}
        idChamadoSelecionado={idChamadoSelecionado}
        emailRespDemandante={emailRespDemandante}
        emailRespDemandado={emailRespDemandado}
        detalheSelecionado={detalheSelecionado}
        handleSubmitDet={handleSubmitDet}
        detalheId={detalheId}
        idChamadoSel={idChamadoSel}
        setListaChamadosDetalhes={setListaChamadosDetalhes}
        handleUpdateDet={handleUpdateDet}
        // dados={idChamadoSelecionado}
      />

      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 2,
            '@media (max-width: 600px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              textShadow: '-1px -1px 6px #000000',
              color: 'rgb(248, 249, 250)',
              p: [2],
            }}
          >
            <BsFillMegaphoneFill size={28} />
            <Typography variant="h4">
              Help Amvox - Gerenciador de Chamados
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate(-1)}
            startIcon={<KeyboardBackspaceIcon />}
          >
            Voltar
          </Button>
        </Box>

        <Grid container columnSpacing={1} rowSpacing={1} sx={{ p: 1, pt: 0 }}>
          <Grid item xs={6} sm={4}>
            <NumberBox3
              title={'Chamados Abertos'}
              monthValueOrDefault={dadosCard.qtdAberto}
              color={'#001E3F'}
              status={1}
              fontColor={'#fff'}
              iconSetor={'inteligencia'}
              setor={`${dadosCard.chamadosX?.[0]?.setorDemandado}`}
              noVariantCurrent
              noLink
              toLink={''}
              tooltipStatus={'Id Card - 9'}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <NumberBox3
              title={'Chamados Fechados'}
              monthValueOrDefault={dadosCard.qtdFechado}
              color={'#001E3F'}
              status={1}
              fontColor={'#fff'}
              iconSetor={'inteligencia'}
              setor={`${dadosCard.chamadosX?.[0]?.setorDemandado}`}
              noVariantCurrent
              // loader={removeLoading}
              noLink
              tooltipStatus={'Id Card - 9'}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <NumberBox3
              title={'Total de Chamados'}
              monthValueOrDefault={dadosCard.qtdTotal}
              color={'#001E3F'}
              status={1}
              fontColor={'#fff'}
              iconSetor={'inteligencia'}
              setor={`${dadosCard.chamadosX?.[0]?.setorDemandado}`}
              // loader={removeLoading}
              noVariantCurrent
              noLink
              tooltipStatus={'Id Card - 9'}
            />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            gap: '32px',
            marginBottom: '10px',
            marginTop: '10px',
            padding: '24px',
            alignItems: 'end',
            width: '100%',
            height: '120px',
            bgcolor: 'white',
            boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
            flexWrap: 'wrap',
            borderRadius: '8px',
          }}
        >
          <Box>
            <FormLabel>Nº Chamado</FormLabel>
            <TextField
              fullWidth
              sx={{
                backgroundColor: '#fff',
                display: 'flex',
                maxWidth: '142px',
                '& .MuiInputBase-root': {
                  borderRadius: '8px',
                  height: '48px',
                  '&:focus-within': {
                    border: '1px solid lightgray',
                  },
                },
              }}
              inputProps={{ style: { height: '48px' } }}
              value={filtro.id}
              onChange={(e) => setFiltro({ ...filtro, id: e.target.value })}
            />
          </Box>
          <Box>
            <FormLabel>Status</FormLabel>
            <TextField
              select
              fullWidth
              sx={{
                backgroundColor: '#fff',
                display: 'flex',
                maxWidth: '142px',
                '& .MuiInputBase-root': {
                  borderRadius: '8px',
                  height: '48px',
                  '&:focus-within': {
                    border: '1px solid lightgray',
                  },
                },
              }}
              inputProps={{ style: { height: '48px' } }}
              value={filtro.status}
              onChange={handleChange}
            >
              {statusSelect.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box>
            <Button
              onClick={handleClear}
              variant="outlined"
              sx={{
                color: '#6E6E6E',
                height: '48px',
                border: '2px solid #CCCCCC',
                transition:
                  'background-color 0.5s ease, transform 0.3s ease-in-out',
                border: '2px solid #CCCCCC',

                '&:hover': {
                  transform: 'scale(1.1)',
                  transition:
                    'background-color 0.5s ease, transform 0.3s ease-in-out',
                  border: '2px solid #CCCCCC',
                },
              }}
              startIcon={<HighlightOffIcon />}
            >
              Limpar Filtro
            </Button>
          </Box>
          <Box sx={{ width: '120px', height: '48px', display: 'flex' }}>
            <Button
              variant="contained"
              color="success"
              startIcon={<SiMicrosoftexcel />}
              type="submit"
              onClick={exportExcelFile}
            >
              Excel
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            width: '100%',
            overflow: 'scroll',
            flexGrow: '3',
          }}
        >
          {removeLoading ? (
            <XTabelaChamados
              data={listaChamados}
              showModal={setOpen}
              setDadosTabela={setDadosTabela}
              setListaChamadosDetalhes={setListaChamadosDetalhes}
              setFormData={setFormData}
            />
          ) : (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={'100%'}
              height={600}
            />
          )}
        </Box>
      </Box>
    </LayoutNovo>
  );
}
