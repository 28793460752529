import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  IconButton,
  Paper,
} from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import ModalEditar from '../ModalEditar';

const columns = [
  { label: 'Código', key: 'codigo' },
  { label: 'Descrição', key: 'descricao' },
  { label: 'Qtd Palete', key: 'qtdProdutosPalet' },
  { label: 'Porte Produto', key: 'porteProduto' },
  { label: 'Fornecedor', key: 'fornecedor' },
  { label: 'Tipo Fornecedor', key: 'tipoFornecedor' },
  { label: 'Operadores', key: 'operadores' },
  { label: 'Meta Hora', key: 'produtoMetaHora' },
  { label: 'Tx Aproveitamento', key: 'taxaAproveitamento' },
  { label: 'Observações', key: 'observacoes' },
  { label: 'Peso Bruto (KG)', key: 'pesoBruto' },
  { label: 'Peso Palete (KG)', key: 'pesoPalet' },
];

const Row = ({ row, headers, GetDados }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [dataEdicao, setDataEdicao] = useState(null);

  const handleOpenModal = (rowData) => {
    setDataEdicao(rowData);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setDataEdicao(null);
  };

  const renderCell = (value) => (value ? value : '-');

  return (
    <TableRow>
      <TableCell align="center">
        <IconButton
          size="small"
          color="primary"
          onClick={() => handleOpenModal(row)}
        >
          <ModeEditIcon />
        </IconButton>
      </TableCell>

      {headers.map((column) => (
        <TableCell
          key={column.key}
          align="center"
          sx={{ whiteSpace: 'nowrap' }}
        >
          {renderCell(row[column.key])}
        </TableCell>
      ))}

      {row.listaInsumosUtilizados?.map((item, index) => (
        <TableCell key={index} align="center" sx={{ whiteSpace: 'nowrap' }}>
          {renderCell(item.qtdInsumosUsados)}
        </TableCell>
      ))}

      {isModalOpen && (
        <ModalEditar
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          row={dataEdicao}
          GetDados={GetDados}
        />
      )}
    </TableRow>
  );
};

export default function NewTable({ data, GetDados }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} sx={{ maxHeight: 700 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ fontWeight: 'bold' }}>
              Ações
            </TableCell>
            {columns.map((column) => (
              <TableCell
                key={column.key}
                align="center"
                sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
              >
                {column.label}
              </TableCell>
            ))}
            {/* Cabeçalhos adicionais de insumos */}
            {data[0]?.listaInsumosUtilizados?.map((item, index) => (
              <TableCell
                key={`header-insumo-${index}`}
                align="center"
                sx={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}
              >
                {item.insumo?.nome || `Insumo ${index + 1}`}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((item) => (
              <Row
                key={item.id}
                row={item}
                headers={columns}
                GetDados={GetDados}
              />
            ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              count={data.length}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              labelRowsPerPage="Linhas por página"
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
