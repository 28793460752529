import { apiInteligencia } from './apis';

export const postRelatorioApontamentos = async (body) => {
  try {
    const { data } = await apiInteligencia.post(
      `ApontamentoInteligencia/RelatorioApontamentos`,
      body,
      {
        responseType: 'blob',
      }
    );
    return data;
  } catch (error) {
    console.error('Erro ao obter relatório de apontamentos:', error);
    throw error;
  }
};
