import axios from 'axios';

import { isAuthenticated, getToken } from './auth';

export const apiAlunos = axios.create({
  baseUrl: process.env.REACT_APP_API_ALUNOS,
});

export const apiFabrica = axios.create({
  baseUrl: process.env.REACT_APP_API_FABRICA,
});
export const apiFabrica_operacao = axios.create({
  baseUrl: process.env.REACT_APP_API_FABRICA_OPERACAO,
});

export const apiLogin = axios.create({
  baseUrl: process.env.REACT_APP_API_LOGIN,
});

export const apiFabrica_Posvenda = axios.create({
  baseUrl: process.env.REACT_APP_API_POSVENDA,
});
export const apiFabricaADM = axios.create({
  baseUrl: process.env.REACT_APP_API_FABRICAADM,
});

export const apiFabricaAPOIO = axios.create({
  baseUrl: process.env.REACT_APP_API_APOIO,
});

export const apiFabricaDev = axios.create({
  baseUrl: process.env.REACT_APP_API_FABRICADEV,
});

export const apiFactory = axios.create({
  baseUrl: process.env.REACT_APP_API_FACTORY,
});

export const apiInteligencia = axios.create({
  baseUrl: process.env.REACT_APP_API_INTELIGENCIA,
});

export const apiNotaFiscal = axios.create({
  baseURL: process.env.REACT_APP_API_NOTAFISCAL,
});

export const apiCotacao = axios.create({
  baseUrl: process.env.REACT_APP_API_COTACAO,
});

export const apiMunicipiosIBGE = axios.create({
  baseUrl: `https://servicodados.ibge.gov.br/api/v1/localidades`,
});

apiAlunos.defaults.baseURL = process.env.REACT_APP_API_ALUNOS;
apiFabrica.defaults.baseURL = process.env.REACT_APP_API_FABRICA;
apiFabrica.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiFabrica_operacao.defaults.baseURL =
  process.env.REACT_APP_API_FABRICA_OPERACAO;
apiFabrica_operacao.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiFabricaADM.defaults.baseURL = process.env.REACT_APP_API_FABRICAADM;
apiFabricaADM.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiFabrica_Posvenda.defaults.baseURL = process.env.REACT_APP_API_POSVENDA;
apiFabrica_Posvenda.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiLogin.defaults.baseURL = process.env.REACT_APP_API_LOGIN;
apiLogin.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiFabricaAPOIO.defaults.baseURL = process.env.REACT_APP_API_APOIO;
apiFabricaAPOIO.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiFactory.defaults.baseURL = process.env.REACT_APP_API_FACTORY;
apiFactory.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};
apiInteligencia.defaults.baseURL = process.env.REACT_APP_API_INTELIGENCIA;
apiInteligencia.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};

apiFabricaDev.defaults.baseURL = process.env.REACT_APP_API_FABRICADEV;
apiFabricaDev.defaults.headers = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH',
};

apiCotacao.defaults.baseURL = 'https://economia.awesomeapi.com.br/json';
apiMunicipiosIBGE.defaults.baseURL =
  'https://servicodados.ibge.gov.br/api/v1/localidades';
apiFabrica.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

apiFabrica_operacao.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

apiFabricaADM.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

apiLogin.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

apiFabrica_Posvenda.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }
  return config;
});

apiFabricaAPOIO.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});
apiFactory.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});
apiInteligencia.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

apiFabricaDev.interceptors.request.use(async (config) => {
  if (isAuthenticated()) {
    if (config !== null && config.headers)
      config.headers.Authorization = `Bearer ${getToken()}`;
  }

  return config;
});

// apiFabrica.interceptors.response.use(
//   async (status) => {
//     return Promise.resolve(status);
//   },
//   async (error) => {
//     const response = error.response;

//     if (response.status)
//       if (response.status === 401) window.location.href = '/401';

//     return Promise.reject(error);
//   }
// );

export const apiParaTestesDev = axios.create({
  baseURL: 'http://localhost:3333',
});
