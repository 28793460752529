import React, { useCallback } from 'react';
import LayoutNovo from '../../components/LayoutNovo';
import { Box } from '@mui/joy';
import { TextField } from '@mui/material';
import Logo from '../../assets/logo_amvox.png';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import debounce from '../../utils/debounce';
import { Grid } from '@mui/joy';
import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { consultaGalpao } from '../../services/produtos.service';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Modal from '@mui/material/Modal';
import { gerarQrCode } from '../../services/produtos.service';
import { useToast } from '../../hooks/components/toast.hook';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import formatInvert from './components/formatInvert';
import dayjs from 'dayjs';
import Autocomplete from '@mui/material/Autocomplete';
import Produto from '../../assets/produto4.png';
import { consultaProdutos } from '../../services/produtos.service';
import { useEffect } from 'react';
import { consultaProdutosImagem } from '../../services/produtos.service';
import { realizarCheckQrCode } from '../../services/apontamentos.service';
import ModalCheckQrCode from './components/ModalCheckQrCode';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 150,
  height: 150,
  bgcolor: '#e4e4e4',
  border: '0.5px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: '10%',
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: 350,
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  display: 'flex',
};

export default function GeradorQrCode() {
  const [value, setValue] = React.useState('');
  const [linha, setLinha] = useState('');
  const [galpaoid, setGalpaoId] = useState('');
  const [dataInicial, setDataInicial] = useState(null);
  const [produtos, setProdutos] = useState([]);
  const [listaApelidos, setListaApelidos] = useState([]);
  const [descricao, setDescricao] = useState('');
  const [apelidosArray, setApelidosArray] = useState([]);
  const [selectedApelido, setSelectedApelido] = useState(null);
  const [codigosArray, setCodigosArray] = useState([]);
  const [eanArray, setEanArray] = useState([]);
  const [imagemProduto, setImagemProduto] = useState('');
  const [selectedEan, setSelectedEan] = useState(false);
  const [siglaArray, setSiglaArray] = useState([]);
  const [open, setOpen] = useState(true);
  const handleClose = () => setOpen(false);
  const [openModal, setOpenModal] = React.useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);
  const [eanObtido, setEanObtido] = useState('');
  const [galpoes, setGalpoes] = useState([]);
  const [qrGerado, setQrGerado] = useState([]);
  const [startEstado, setStartEstado] = useState();
  const [dadosCheckQrCode, setDadosCheckQrCode] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filtro, setFiltro] = useState({
    datainicio: null,
    datafim: null,
  });
  const formatDateForDisplay = (date) => {
    return dayjs(date).format('DD/MM/YYYY');
  };
  const Data = formatInvert(formatDateForDisplay(dataInicial)).toString();
  const [filtroApi, setFiltroApi] = useState({
    data: '',
    codigoProduto: '',
    linha: '',
    galpaoId: '',
    quantidade: 0,
  });
  const { addToast } = useToast();

  useEffect(() => {
    setFiltroApi({
      data: Data,
      codigoProduto: selectedApelido ? selectedApelido[0]?.codigo : '',
      linha: linha,
      galpaoId: galpaoid,
      quantidade: parseInt(value),
    });
  }, [Data, selectedApelido, linha, galpaoid, value]);

  const handleInputChangeQuant = (e) => {
    setValue(e.target.value);
  };
  const handleInputgalpao = (event) => {
    const galpaoNome = event.target.value;

    switch (galpaoNome) {
      case 'REISTAR':
        setGalpaoId(1);
        break;
      case 'POS-VENDA':
        setGalpaoId(2);
        break;
      case 'ETIQUETAGEM':
        setGalpaoId(3);
        break;
      case 'CORDEBRAS':
        setGalpaoId(4);
        break;
      default:
        break;
    }
  };

  const handleInputLinha = (e) => {
    setLinha(e.target.value);
  };

  const Linha = [
    { id: 0, nome: 'LINHA - 1' },
    { id: 1, nome: 'LINHA - 2' },
    { id: 2, nome: 'LINHA - 3' },
    { id: 3, nome: 'LINHA - 4' },
    { id: 4, nome: 'LINHA - 5' },
    { id: 5, nome: 'LINHA - 6' },
    { id: 6, nome: 'LINHA - 7' },
    { id: 7, nome: 'LINHA - 8' },
    { id: 8, nome: 'LINHA - 9' },
    { id: 9, nome: 'TÉCNICO' },
  ];
  const Linhamodal = [
    { id: 0, nome: 'LINHA - 1' },
    { id: 1, nome: 'LINHA - 2' },
    { id: 2, nome: 'LINHA - 3' },
    { id: 3, nome: 'LINHA - 4' },
    { id: 4, nome: 'LINHA - 5' },
    { id: 5, nome: 'LINHA - 6' },
    { id: 6, nome: 'LINHA - 7' },
    { id: 7, nome: 'LINHA - 8' },
    { id: 8, nome: 'LINHA - 9' },
    { id: 9, nome: 'TÉCNICO' },
  ];
  const Galpao = [
    { id: 0, nome: '' },
    { id: 1, nome: 'REISTAR' },
    { id: 1, nome: 'POS-VENDA' },
    { id: 2, nome: 'ETIQUETAGEM' },
    { id: 3, nome: 'CORDEBRAS' },
  ];
  const dataAtual = new Date();
  const dia = dataAtual.getDate();
  const maxDia = dataAtual.getDate() + 7;
  const mes = dataAtual.getMonth() + 1;
  const ano = dataAtual.getFullYear();
  const dataFormatada = `${mes}/${dia}/${ano}`;
  const dataFormatadaModal = `${dia}/${mes}/${ano}`;
  const maxDataFormatada = `${mes}/${maxDia}/${ano}`;

  const handleListProdutos = () => {
    const uniqueApelidosSet = new Set();
    const uniqueCodigoSet = new Set();
    const uniqueeanSet = new Set();
    const uniqueSiglaSet = new Set();

    produtos.forEach((item) => {
      uniqueApelidosSet.add(item.apelido);
      uniqueCodigoSet.add(item.codigo);
      uniqueeanSet.add(item.ean);
      uniqueSiglaSet.add(item.sigla);
    });

    const uniqueApelidosArray = Array.from(uniqueApelidosSet);
    const uniqueCodigoArray = Array.from(uniqueCodigoSet);
    const uniqueEanArray = Array.from(uniqueeanSet);
    const uniqueSiglaArray = Array.from(uniqueSiglaSet);

    setCodigosArray(uniqueCodigoArray);
    setApelidosArray(uniqueApelidosArray);
    setEanArray(uniqueEanArray);
    setSiglaArray(uniqueSiglaArray);
  };

  useEffect(() => {
    handleListProdutos();
  }, [produtos]);

  useEffect(() => {
    setListaApelidos(apelidosArray);
  }, [apelidosArray]);

  const handleConsultaProdutos = () => {
    consultaProdutos()
      .then((res) => {
        setProdutos(res);
        handleClose();
      })
      .catch((error) => {});
  };

  const handleConsultaFoto = () => {
    consultaProdutosImagem({
      codigo: selectedApelido[0]?.codigo,
      apelido: selectedApelido[0]?.apelido,
      sigla: selectedApelido[0]?.sigla,
      ean: selectedApelido[0]?.ean,
    })
      .then((res) => {
        setImagemProduto(res[0].imagem);
      })
      .catch((error) => {
        console.error('Erro ao listar Estoque:', error);
      });
  };
  // {
  //   codigo: selectedApelido[0]?.codigo,
  //   apelido: selectedApelido[0]?.apelido,
  //   sigla: selectedApelido[0]?.sigla,
  //   ean: selectedApelido[0]?.ean,
  // }

  useEffect(() => {
    if (selectedApelido) {
      handleConsultaFoto();
    }
  }, [selectedApelido]);

  const handleConsultaGalpao = () => {
    consultaGalpao()
      .then((res) => {
        setGalpoes(res);
      })
      .catch((error) => {});
  };

  const handleGerarQrCode = () => {
    gerarQrCode(filtroApi)
      .then((res) => {
        setQrGerado(res);
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'QRCODE gerado com sucesso!',
        });
      })
      .catch((error) => {
        addToast({
          type: 'danger',
          title: 'Erro.',
          description: 'Preencha os dados corretamente!',
        });
      });
  };

  useEffect(() => {
    handleConsultaProdutos();
    handleConsultaGalpao();
  }, []);

  const changeHandler = (event, value) => {
    const inputValue = event.target.value;
    if (inputValue == '') {
      setSelectedEan(false);
    }

    const objetoEncontrado = produtos.filter((objeto) => {
      for (const propriedade in objeto) {
        if (
          objeto.hasOwnProperty(propriedade) &&
          typeof objeto[propriedade] === 'string'
        ) {
          if (objeto[propriedade].includes(value || inputValue)) {
            return true;
          }
        }
      }
      return false;
    });
    setSelectedApelido(objetoEncontrado);
    setSelectedEan(true);
  };

  const changeHandlerEan = (event, value) => {
    const inputValue = event.target.value;

    if (event.key === 'Backspace') {
      setSelectedEan(false);
    }
    setEanObtido(event.target.value);

    const objetoEncontrado = produtos.filter((objeto) => {
      for (const propriedade in objeto) {
        if (
          objeto.hasOwnProperty(propriedade) &&
          typeof objeto[propriedade] === 'string'
        ) {
          if (objeto[propriedade].includes(value || inputValue)) {
            return objeto;
          }
        }
      }
      return false;
    });
    setSelectedApelido(objetoEncontrado);
  };

  // const handleLimpar = () => {
  //   setLinha('');
  //   setValue('');
  //   setGalpaoId('');
  //   setDataInicial(null);
  //   setProdutos('');
  //   setListaApelidos('');
  //   setDescricao('');
  //   setApelidosArray('');
  //   setSelectedApelido(null);
  // };

  const handleCheckarQrCode = useCallback(
    (value) => {
      realizarCheckQrCode(value)
        .then((res) => setDadosCheckQrCode(res))
        .finally(() => setLoading(false));
    },
    [qrGerado]
  );

  return (
    <LayoutNovo>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CircularProgress
            color="error"
            size={40}
            sx={{ marginLeft: '20px' }}
          />
          <Typography>Carregando..</Typography>
        </Box>
      </Modal>

      <ModalCheckQrCode
        qrCodeCheck={dadosCheckQrCode}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        loading={loading}
      />
      <Box sx={{ Width: '100%', height: '650px', padding: '10px' }}>
        <Box sx={{ display: 'flex', backgroundColor: '#000000' }}>
          <Box sx={{ width: '10%' }}>
            <img src={Logo} alt="" width="60%" />
          </Box>
          <Box
            sx={{
              display: 'block',
              color: 'white',
              paddingTop: '10px',
              paddingLeft: '0px',
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
              GERADOR
            </Typography>
            <Typography variant="h5" sx={{ fontWeight: 'bolder' }}>
              QRCODE
            </Typography>
          </Box>
        </Box>
        <br />
        <Box sx={{ paddingLeft: '35px' }}>
          <Typography>EAN</Typography>

          <TextField
            onChange={(event) => changeHandlerEan(event)}
            onKeyDown={changeHandlerEan}
            size="small"
            id="outlined-basic"
            variant="filled"
            sx={{ width: '69.2%' }}
            value={
              selectedEan === true
                ? selectedApelido
                  ? selectedApelido[0]?.ean
                  : ''
                : eanObtido
            }
          />
        </Box>
        <Box sx={{ display: 'flex', width: '100%', paddingLeft: '35px' }}>
          <Box sx={{ display: 'column', width: '70%' }}>
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                gap: 1,
              }}
            >
              <Box sx={{ paddingTop: '10px', width: '70%' }}>
                <Autocomplete
                  onChange={(event, value) => changeHandler(event, value)}
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  value={selectedApelido ? selectedApelido[0]?.apelido : ''}
                  options={listaApelidos}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    0, (<TextField {...params} label="APELIDO" />)
                  )}
                />
              </Box>
              <Box sx={{ paddingTop: '10px', width: '70%' }}>
                <Autocomplete
                  onChange={(event, value) => changeHandler(event, value)}
                  variant="filled"
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={codigosArray}
                  value={selectedApelido ? selectedApelido[0]?.codigo : ''}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="CODIGO" />
                  )}
                />
              </Box>
              <Box sx={{ paddingTop: '10px', width: '70%' }}>
                <Autocomplete
                  onChange={(event, value) => changeHandler(event, value)}
                  variant="filled"
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={siglaArray}
                  value={selectedApelido ? selectedApelido[0]?.sigla : ''}
                  sx={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField {...params} label="SIGLA" />
                  )}
                />
              </Box>
            </Box>
            <Box sx={{ textAlign: 'center', mt: 2 }}>
              <Typography>DESCRIÇÃO</Typography>
              <TextField
                value={selectedApelido ? selectedApelido[0]?.nome : ''}
                size="small"
                fullWidth
                id="outlined-basic"
                variant="filled"
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                my: 1,
              }}
            >
              <Box fullWidth sx={{ flex: 1 }}>
                <FormControl size="small" variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label" size="small">
                    LINHA
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    sx={{ fontSize: '0.8rem' }}
                    onChange={(event, value) => handleInputLinha(event, value)}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {Linha.map((linha) => (
                      <MenuItem key={linha.id} value={linha.id}>
                        {linha.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box fullWidth sx={{ flex: 1 }}>
                <FormControl size="small" variant="filled" fullWidth>
                  <InputLabel id="demo-simple-select-filled-label" size="small">
                    GALPÃO
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    sx={{ fontSize: '0.8rem' }}
                    onChange={handleInputgalpao}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {galpoes.map((galpao) => (
                      <MenuItem key={galpao.galpaoId} value={galpao.nome}>
                        {galpao.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box fullWidth sx={{ flex: 1 }}>
                <TextField
                  type="number"
                  fullWidth
                  value={value}
                  onChange={handleInputChangeQuant}
                  label="Quantidade"
                  size="small"
                  variant="filled"
                />
              </Box>
              <Box sx={{ flex: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    minDate={dataFormatada}
                    maxDate={maxDataFormatada}
                    value={dataInicial} // Configure o valor do DatePicker para dataInicial
                    onChange={(date) =>
                      debounce(() => {
                        const formattedDate = date
                          ? dayjs(date).format('YYYY-MM-DD')
                          : null;
                        setDataInicial(formattedDate); // Atualize o estado dataInicial
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Data Inicial"
                        variant="filled"
                        size="small"
                        fullWidth
                        sx={{ bgcolor: '#dee2e6', borderRadius: 2 }}
                        value={formatDateForDisplay(dataInicial)}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ width: '100%' }}>
              <ButtonGroup
                sx={{ width: '100%', paddingLeft: '0px' }}
                variant="contained"
                aria-label="outlined primary button group"
              >
                <Button
                  sx={{ width: '80%' }}
                  size="large"
                  color="success"
                  onClick={() => handleGerarQrCode()}
                >
                  GERAR QRCODE
                </Button>
                <Button
                  sx={{ width: '20%' }}
                  size="large"
                  onClick={() => {
                    handleOpenModal();
                    handleCheckarQrCode(qrGerado);
                  }}
                >
                  CHECK
                </Button>
                {/* <Button
                  sx={{ width: '25%', backgroundColor: '#ffb11b' }}
                  size="large"
                  onClick={() => {
                   handleLimpar()
                  }}
                >
                  Limpar.
                </Button> */}
              </ButtonGroup>
            </Box>
            <br />
            <Box sx={{ paddingLeft: '0px', textAlign: 'center' }}>
              <Typography>QRCODE GERADO</Typography>
              <TextField
                size="small"
                id="outlined-basic"
                variant="outlined"
                sx={{ width: '70%' }}
                value={qrGerado ? qrGerado[0] : ''}
              />
            </Box>
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-around',
              }}
            >
              {' '}
              <Box sx={{ paddingTop: '0px', width: 1 / 2 }}>
                <Typography sx={{ color: 'green', fontWeight: 'bolder' }}>
                  QRCODE INICIAL
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="filled"
                  value={qrGerado ? qrGerado[0] : ''}
                />
              </Box>
              <Box sx={{ paddingTop: '0px', paddingLeft: '5px', width: 1 / 2 }}>
                <Typography color="error" sx={{ fontWeight: 'bolder' }}>
                  QRCODE FINAL
                </Typography>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-basic"
                  variant="filled"
                  value={qrGerado ? qrGerado[1] : ''}
                />
              </Box>
            </Box>
          </Box>
          <Box
            component="img"
            sx={{
              width: '30vw',
              objectFit: 'contain',
              height: '45vh',
            }}
            alt="Imagem do Produto"
            src={`data:image/png;base64,${imagemProduto}`}
          />
        </Box>
      </Box>
    </LayoutNovo>
  );
}
